import request from "@/core/services/axios";
export function homeworkPage(params) {
  // 分页查询 作业
  return request({
    url: `/homework/homework/page`,
    method: "get",
    params: params,
  });
}
export function homeworkClassRate(params) {
  // 获取作业下各个班级录入比例
  return request({
    url: `/homework/homework/class/rate`,
    method: "get",
    params: params,
  });
}
export function deleteHomework(params) {
  // 彻底删除-删除作业
  return request({
    url: `/homework/homework/delete`,
    method: "get",
    params: params,
  });
}
export function deleteHomeworkToTrash(params) {
  // 删除作业到回收站
  return request({
    url: `/homework/homework/trash`,
    method: "get",
    params: params,
  });
}
export function updateHomework(params) {
  // 修改作业
  return request({
    url: `/homework/homework/update`,
    method: "post",
    data: params,
  });
}
export function reportClasses(params) {
  // 获取报告作业班级
  /**
   * @param {Number} hwId 作业id
   */
  return request({
    url: `/homework/report/classes`,
    method: "get",
    params: params,
  });
}
export function homeworkClasNums(params) {
  // 获取作业班级
  /**
   * @param {Number} year 入学年份
   * @param {Number} level 学段
   */
  return request({
    url: `/homework/homework/classes`,
    method: "get",
    params: params,
  });
}
export function downloadHomework(params) {
  // 下载作业
  return request({
    url: `/homework/homework/download`,
    method: "get",
    params: params,
  });
}

export function downloadStatHomework(params) {
  // 下载作业统计
  return request({
    url: `/homework/homework/downloadstat`,
    method: "post",
    data: params,
  });
}

export function recoverHomework(params) {
  // 从回收站恢复作业
  return request({
    url: `/homework/homework/recover`,
    method: "get",
    params: params,
  });
}
export function trashPage(params) {
  // 回收站分页查询
  return request({
    url: `/homework/homework/trash/page`,
    method: "get",
    params: params,
  });
}
export function resPage(params) {
  // 资源中心作业卡查询
  return request({
    url: `/homework/homework/res/page`,
    method: "get",
    params: params,
  });
}
export function copySheet(params) {
  // 资源中心复制作业卡
  return request({
    url: `/homework/homework/copy`,
    method: "get",
    params: params,
  });
}
export function publishHomework(params) {
  // 布置作业
  return request({
    url: `/homework/homework/publish`,
    method: "post",
    data: params,
  });
}
export function deleteMyHomework(params) {
  // 资源中心删除我的作业卡
  return request({
    url: `/homework/homework/sheet/delete`,
    method: "get",
    params: params,
  });
}
export function cancleShare(params) {
  // 资源中心共享|取消共享我的作业卡
  return request({
    url: `/homework/homework/sheet/share`,
    method: "get",
    params: params,
  });
}
export function homeworkInfo(params) {
  // 作业信息查询
  return request({
    url: `/homework/report/info`,
    method: "get",
    params: params,
  });
}
export function homeworkAnalysis(params) {
  // 成绩分析查询
  return request({
    url: `/homework/report/score/analysis`,
    method: "get",
    params: params,
  });
}
export function distributionHomwork(params) {
  // 分数段区间
  return request({
    url: `/homework/report/score/distribution`,
    method: "post",
    data: params,
  });
}
export function questionAnalysis(params) {
  // 作业试题分析
  return request({
    url: `/homework/report/question/analysis`,
    method: "get",
    params: params,
  });
}
export function questionpointAnalysis(params) {
  // 作业知识点分析
  return request({
    url: `/homework/report/questionpoint/analysis`,
    method: "get",
    params: params,
  });
}
export function studentScorePage(params) {
  // 作业学生成绩分页查询
  return request({
    url: `/homework/report/score`,
    method: "get",
    params: params,
  });
}
export function exportScore(params) {
  // 作业学生成绩导出
  return request({
    url: `/homework/report/score/export`,
    method: "get",
    params: params,
  });
}
export function statQuestion(params) {
  // 题目统计
  return request({
    url: `/homework/report/comment/question/statistics`,
    method: "get",
    params: params,
  });
}
export function statStudent(params) {
  // 学生统计
  return request({
    url: `/homework/report/comment/student/statistics`,
    method: "get",
    params: params,
  });
}
export function commentExport(params) {
  // 导出题目讲评
  return request({
    url: `/homework/report/comment/export`,
    method: "get",
    params: params,
  });
}
export function questionStatistics(params) {
  // 导出题目讲评
  return request({
    url: `/homework/report/question/statistics`,
    method: "get",
    params: params,
  });
}
export function studentStatistics(params) {
  // 导出学生统计
  return request({
    url: `/homework/report/student/statistics`,
    method: "get",
    params: params,
  });
}
export function templateModel(params) {
  // 下载上传模板
  return request({
    url: `/homework/homework/get/template`,
    method: "get",
    params: params,
  });
}
export function homeworkSubject(params) {
  // 下载上传模板
  return request({
    url: `/homework/homework/auth/subject`,
    method: "get",
    params: params,
  });
}
export function levelyear(params) {
  // 学段、学年权限查询
  return request({
    url: `/homework/homework/auth/levelyear`,
    method: "get",
    params: params,
  });
}
