<template>
  <div class="HomeworkItem">
    <div class="header">
      <div class="title">
        <el-tooltip
          class="item"
          effect="dark"
          :content="data.subjectName"
          placement="top"
        >
          <div class="subject">{{ data.subjectName }}</div>
        </el-tooltip>

        <div class="year">{{ getLevelName(data.level) }}{{ data.year }}级</div>
        <el-tooltip
          class="item"
          effect="dark"
          :content="data.homeworkName"
          placement="top"
        >
          <div class="name">{{ data.homeworkName }}</div>
        </el-tooltip>

        <div class="time">时间：{{ data.beginTime }}～{{ data.endTime }}</div>
      </div>
      <div class="right">
        <div class="deleteTime">删除时间：{{ data.trashTime }}</div>
        <div class="No">编号：{{ data.code }}</div>
      </div>
    </div>
    <div class="classNums">班级：{{ getClassNum(data.classNums) }}</div>
    <div class="footer">
      <div class="left">
        已录入：
        <el-popover
          ref="popover"
          v-model="showPoppver"
          placement="bottom"
          trigger="click"
          @show="showPoppverChange"
        >
          <div slot="reference" class="progress">
            <el-progress
              :percentage="rate2Percentage(data.rate)"
              style="width: 303px; margin-right: 10px"
              :stroke-width="10"
              :format="format"
            ></el-progress>
            {{ data.finishedCount }}/{{ data.sheetCount }}人
            <i
              :class="[
                'el-icon-caret-bottom',
                showPoppver ? 'rotate180' : 'rotate0',
              ]"
            ></i>
          </div>
          <div v-if="classNumData.length" class="progress-list">
            <div class="list-wrapper">
              <div
                v-for="(item, index) in classNumData"
                :key="index"
                class="progress-item"
              >
                <div class="classNum">{{ item.classNum }}</div>
                <el-progress
                  :percentage="rate2Percentage(item.rate)"
                  style="width: 303px; margin: 0 10px"
                  :stroke-width="10"
                  :format="format"
                ></el-progress>
                <div class="count">
                  {{ item.finishedCount }}/{{ item.sheetCount }}人
                </div>
              </div>
            </div>
          </div>
          <no-data v-else message="暂无数据" />
        </el-popover>
      </div>
      <div class="right">
        <el-button @click="deleteItem">彻底删除</el-button>
        <el-button @click="recoverItem">恢复</el-button>
      </div>
    </div>
  </div>
</template>
<script>
import { levelOptions } from "@/core/util/constdata";
import {
  deleteHomework,
  homeworkClassRate,
  recoverHomework,
} from "@/core/api/homework/index";
export default {
  name: "HomeworkItem",
  components: {},
  props: {
    data: {
      default: () => {},
      type: Object,
    },
  },
  data() {
    const nowYear = new Date().getFullYear();
    const arr = new Array(nowYear - 2014).fill("");
    let yearOptions = arr.map((item, index) => {
      return { label: index + 2015, value: index + 2015 };
    });
    return {
      form: {
        endTime: "",
        startTime: "",
        level: "",
        year: "",
        name: "",
        classNums: "",
      },
      levelOptions: levelOptions(),
      yearOptions: yearOptions.reverse(),
      loading: false,
      showPoppver: false,
      classNumData: [],
    };
  },
  created() {},
  methods: {
    rate2Percentage(rate) {
      if (rate) {
        return rate * 100;
      }
    },
    recoverItem() {
      this.$confirm("是否恢复当前作业到作业列表？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          await recoverHomework({ hwIds: this.data.hwId });
          this.$emit("update");
          this.$message({
            type: "success",
            message: "提交成功!",
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    async showPoppverChange() {
      this.classNumData = [];
      const res = await homeworkClassRate({ hwId: this.data.hwId });
      this.classNumData = res.data.data;
      this.$nextTick(() => {
        this.$refs.popover.updatePopper();
      });
    },
    getClassNum(classNum) {
      if (!classNum) {
        return "";
      }
      const classNumList = classNum.split(",");
      if (classNumList.length > 5) {
        const classNumStr =
          classNumList.slice(0, 5).join(",") + `等${classNumList.length}个班级`;
        return classNumStr;
      } else {
        return classNum;
      }
    },
    getLevelName(level) {
      const enmu = {
        1: "小",
        2: "初",
        3: "高",
        4: "大",
      };
      if (level) {
        return enmu[level];
      }
      return "";
    },
    format() {
      return "";
    },

    deleteItem() {
      this.$confirm("彻底删除后，作业内容将无法恢复，确定删除吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          await deleteHomework({ hwIds: this.data.hwId, ifClearAll: false });
          this.$emit("update");
          this.$message({
            type: "success",
            message: "删除成功!",
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.HomeworkItem {
  box-sizing: border-box;
  padding: 18px;
  background-color: #fff;
  border: 1px solid #dee0e7;
  margin-bottom: 18px;
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 18px;
    .title {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      .subject {
        background-color: #2474ed;
        border-radius: 4px;
        color: #fff;
        height: 20px;
        padding: 0 6px;
        margin-right: 8px;
        max-width: 72px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
      .year {
        background-color: #f0f4ff;
        border-radius: 4px;
        color: #56575d;
        height: 20px;
        padding: 0 6px;
        margin-right: 14px;
      }
      .name {
        color: #0a1119;
        font-size: 16px;
        margin-right: 8px;
        font-weight: 500;
        max-width: 200px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
    .right {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      font-size: 14px;
      color: #3e4551;
      .deleteTime {
        margin-right: 8px;
      }
    }
  }

  .classNums {
    color: #0a1119;
    font-size: 14px;
    margin-bottom: 18px;
  }
  .footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    .left {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex: 1;
      white-space: nowrap;
      .status {
        margin-left: 20px;
      }
    }
    .right {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      text-align: right;
      flex: 1;
    }
    .progress {
      display: flex;
      align-items: center;
    }
  }
  .rotate180 {
    transform: rotate(180deg);
    transition: transform 0.3s linear;
    cursor: pointer;
  }
  .rotate0 {
    transform: rotate(0deg);
    transition: transform 0.3s linear;
    cursor: pointer;
  }
}
.progress-list {
  max-height: 320px;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 10px; /* 滚动条宽度 */
    background-color: #f9f9f9; /* 滚动条背景色 */
  }
  &::-webkit-scrollbar-thumb {
    background-color: #bdbdbd; /* 滑块颜色 */
    border-radius: 10px; /* 滑块圆角 */
  }

  /* 自定义滚动条轨道样式 */
  &::-webkit-scrollbar-track {
    background-color: #f0f0f0; /* 轨道颜色 */
  }
  .list-wrapper {
    display: table;
    border-spacing: 10px;
    .progress-item {
      display: flex;
      align-items: center;
      justify-content: center;
      .classNum {
        display: table-cell;
        text-align: right;
      }
      .count {
        display: table-cell;
      }
    }
    .progress-item + .progress-item {
      margin-top: 24px;
    }
  }
}
.el-button + .el-dropdown {
  margin-left: 10px;
}
.checkbox {
  margin-bottom: 22px;
  box-sizing: border-box;
  padding: 0 16px;
}
.timePicker {
  display: flex;
  align-items: center;
  justify-content: center;
  span {
    margin: 0 20px;
  }
}
</style>
