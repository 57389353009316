<template>
  <div v-loading="loading" class="recycle">
    <div class="wrapper">
      <div class="nav">
        <div class="back" @click="toBack">
          <img src="@/assets/newExam/back.svg" alt="" />返回
        </div>
        <div class="clear" @click="clearAll">
          <img src="@/static/homework/recycle bin_icon03@1x.png" alt="" />清空
        </div>
      </div>
      <div class="list">
        <template v-if="tableData.length">
          <recycleItem
            v-for="(item, index) in tableData"
            :key="index"
            :data="item"
            @update="getData"
          />
        </template>
        <no-data v-else message="暂无数据"></no-data>
        <div style="text-align: right; margin-top: 18px">
          <el-pagination
            :current-page="current"
            :page-sizes="[10, 20, 30, 40]"
            :page-size="pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          >
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import recycleItem from "./components/recycleItem.vue";
import { trashPage, deleteHomework } from "@/core/api/homework/index";
export default {
  name: "Recycle",
  components: {
    recycleItem,
  },
  data() {
    return {
      current: 1,
      total: 0,
      pageSize: 10,
      tableData: [],
      loading: false,
    };
  },
  created() {
    this.getData();
  },
  methods: {
    clearAll() {
      this.$confirm("清空后，作业内容将无法恢复，确定清空吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          await deleteHomework({ ifClearAll: true });
          this.getData();
          this.$message({
            type: "success",
            message: "删除成功!",
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    async getData() {
      try {
        this.loading = true;
        const res = await trashPage({
          current: this.current,
          size: this.pageSize,
        });
        this.tableData = res.data.data.records;
        this.total = res.data.data.total;
        this.loading = false;
      } catch {
        this.loading = false;
      }
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.getData();
    },
    handleCurrentChange(val) {
      this.current = val;
      this.getData();
    },
    toBack() {
      this.$router.go(-1);
    },
  },
};
</script>
<style lang="scss" scoped>
.recycle {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  .wrapper {
    width: 1200px;
    .nav {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 18px 0;
      background-color: #fff;
      box-sizing: border-box;
      padding: 18px;
      // border-radius: 8px;
      .back {
        cursor: pointer;
        img {
          margin-right: 8px;
        }
      }
      .clear {
        cursor: pointer;
        img {
          margin-right: 8px;
        }
      }
    }
    .list {
      padding: 18px;
      box-sizing: border-box;
      background: #fff;
      // border-radius: 8px;
    }
  }
}
</style>
